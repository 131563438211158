<template>
  <div class="p-5">
    <div v-if="page === 'users'">
      <v-row>
        <v-col v-for="stat in usersStats" :key="stat.id" cols="3">
          <v-card flat class="p-0 rounded-lg d-flex justify-space-between">
            <v-card-title class="d-flex flex-column align-start">
              <unicon :height="50" :name="stat.icon" :fill="'#0971CE'"></unicon>
              {{ stat.name }}
            </v-card-title>
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size: 2rem; align-self: center"
            >
              <span v-if="stat.stat != -1">
                {{ stat.stat }}
              </span>
              <v-progress-circular v-else color="primary" indeterminate />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat class="white rounded-lg">
            <v-toolbar flat>
              <v-toolbar-title class="primary--text"
                >Users By Region</v-toolbar-title
              >
              <v-spacer />
              <v-btn-toggle mandatory color="primary" rounded>
                <v-btn small @click="usersShowChart = true">Chart</v-btn>
                <v-btn small @click="usersShowChart = false">Table</v-btn>
              </v-btn-toggle>
            </v-toolbar>
            <v-card-text v-if="usersShowChart">
              <apexchart
                v-if="isUserChartDoneLoading"
                type="bar"
                height="400"
                :options="userchartOptions"
                :series="usersByRegion"
              ></apexchart>

              <div
                v-else
                class="text-center mt-5 centercontent"
                style="min-height: 250px"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-simple-table fixed-header height="400px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Region</th>
                      <th class="text-left">Total</th>
                      <th class="text-left">Patients</th>
                      <th class="text-left">Doctors</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in usersbylocation" :key="item.zone">
                      <td>{{ item.zone }}</td>
                      <td>{{ item.data.length }}</td>
                      <td>{{ item.patients }}</td>
                      <td>{{ item.doctors }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="page === 'apps'">
      <v-toolbar flat class="transparent">
        <v-spacer />
        Filter By
        <v-combobox
          class="mx-1"
          v-model="department"
          :items="departments"
          :label="$t('QUES.selectDeprt')"
          filled
          single-line
          hide-details
          height="40"
          style="max-width: 250px"
          rounded
          dense
          item-text="name"
          item-value="id"
          hide-selected
        >
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.name }}
          </template>
        </v-combobox>
      </v-toolbar>
      <v-row>
        <v-col v-for="stat in appsStats" :key="stat.id" cols="3">
          <v-card flat class="p-0 rounded-lg d-flex justify-space-between">
            <v-card-title class="d-flex flex-column align-start">
              <unicon :height="50" :name="stat.icon" :fill="'#0971CE'"></unicon>
              {{ stat.name }}
            </v-card-title>
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size: 2rem; align-self: center"
            >
              <span v-if="stat.stat != -1">
                {{ stat.stat }}
              </span>
              <v-progress-circular v-else color="primary" indeterminate />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat class="white rounded-lg">
            <v-toolbar flat>
              <v-toolbar-title class="primary--text"
                >Appointments Overview</v-toolbar-title
              >
              <v-spacer />
              <v-btn-toggle mandatory color="primary" rounded>
                <v-btn small>Last 30 days</v-btn>
                <v-btn v-if="false" small>All Time</v-btn>
              </v-btn-toggle>
            </v-toolbar>
            <v-card-text>
              <apexchart
                v-if="isAppsChartDoneLoading"
                type="area"
                height="400"
                :options="appschartOptions"
                :series="appsSeries"
              ></apexchart>

              <div
                v-else
                class="text-center mt-5 centercontent"
                style="min-height: 250px"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="page === 'questions'">questions</div>
  </div>
</template>

<script>
// import Settings from './comp/Settings';
// import ManageUsers from './comp/ManageUsers';
export default {
  name: "AdminDashboard",
  components: {
    // ManageUsers,
    // Settings,
  },
  watch: {
    AppsByDepartment(updated) {
      if (updated.length == 0) return;
      this.appsStats[0].stat =
        this.department.id !== 0
          ? this.apps.filter(a => {
              return a.department.id === this.department.id;
            }).length
          : this.apps.length;
      this.appsStats[1].stat = 0;
      this.appsStats[2].stat = 0;
      this.appsStats[3].stat = 0;

      updated.forEach(app => {
        this.appsStats[1].stat = this.appsStats[1].stat + app.video;
        this.appsStats[2].stat = this.appsStats[2].stat + app.clinic;
        this.appsStats[3].stat = this.appsStats[3].stat + app.inprogress;
      });
      this.isAppsChartDoneLoading = false;
      this.appschartOptions.xaxis.categories = updated.slice(0, 31).map(l => {
        return l.date;
      });
      this.appsSeries = [];
      this.appsSeries.push({
        name: "completed",
        data: updated.slice(0, 31).map(l => {
          return l.complete;
        })
      });
      this.appsSeries.push({
        name: "upcoming",
        data: updated.slice(0, 31).map(l => {
          return l.pending;
        })
      });
      this.appsSeries.push({
        name: "deleted",
        data: updated.slice(0, 31).map(l => {
          return l.deleted;
        })
      });
      this.isAppsChartDoneLoading = true;

      this.$forceUpdate();
    }
  },
  computed: {
    AppsByDepartment() {
      if (this.department.id === 0) {
        return this.filterapps(this.apps);
      } else {
        return this.filterapps(
          this.apps.filter(a => {
            return a.department.id === this.department.id;
          })
        );
      }
    }
  },
  methods: {
    filterapps(apps) {
      if (apps.length === 0) return [];
      let toreturn = [];
      let today = this.$util
        .day()
        .format("YYYY/MM/DD")
        .toString();
      let beginin = this.$util
        .day(apps[apps.length - 1].created_on)
        .subtract(1, "day")
        .format("YYYY/MM/DD")
        .toString();
      toreturn.push({
        date: today,
        data: [],
        video: 0,
        clinic: 0,
        inprogress: 0,
        complete: 0,
        deleted: 0,
        pending: 0
      });
      while (toreturn[toreturn.length - 1].date !== beginin) {
        toreturn.push({
          date: this.$util
            .day(toreturn[toreturn.length - 1].date)
            .subtract(1, "day")
            .format("YYYY/MM/DD")
            .toString(),
          data: [],
          video: 0,
          clinic: 0,
          inprogress: 0,
          complete: 0,
          deleted: 0,
          pending: 0
        });
      }
      for (let x in apps) {
        let index = toreturn.findIndex(i => {
          return (
            i.date ===
            this.$util
              .day(apps[x].created_on)
              .format("YYYY/MM/DD")
              .toString()
          );
        });
        toreturn[index].data.push(apps[x]);
        if (apps[x].type === "video") {
          toreturn[index].video++;
        } else if (apps[x].type === "clinic") {
          toreturn[index].clinic++;
        }
        if (apps[x].status === "progress") {
          toreturn[index].inprogress++;
        } else if (apps[x].status === "complete") {
          toreturn[index].complete++;
        } else if (apps[x].status === "deleted") {
          toreturn[index].deleted++;
        } else if (apps[x].status === "pending") {
          toreturn[index].pending++;
        }
      }

      return toreturn;
    },
    async getAppStats() {
      await this.$stats.getAllAppointmentsStats().then(data => {
        if (data !== null) {
          this.apps = data.data.map(app => {
            return {
              id: app.id,
              status: app.status,
              created_on: app.created_on,
              type: app.type,
              department: app.doctor.department
            };
          });
        }
      });
    },
    async getUsersStats() {
      await this.$stats.getAllUsersStats().then(data => {
        if (data !== null) {
          this.usersStats[0].stat = data.data.length;
          this.$forceUpdate();
          this.usersStats[2].stat = data.data.filter(u => {
            return u.role.name === "doctor";
          }).length;
          this.$forceUpdate();
          this.usersStats[3].stat = data.data.filter(u => {
            return u.role.name === "patient";
          }).length;
          this.$forceUpdate();

          this.$util.getDS().presence.getAll((error, usernames) => {
            if (error === null) this.usersStats[1].stat = usernames.length + 1;
            else this.usersStats[1].stat = 0;
            this.$forceUpdate();
          });

          data.data.forEach(user => {
            let index = this.usersbylocation.findIndex(i => {
              return i.zone === user.timezone;
            });
            if (index !== -1) {
              this.usersbylocation[index].data.push(user);
              user.role.name === "doctor"
                ? this.usersbylocation[index].doctors++
                : this.usersbylocation[index].doctors;
              user.role.name === "patient"
                ? this.usersbylocation[index].patients++
                : this.usersbylocation[index].patients;
            } else {
              this.usersbylocation.push({
                zone: user.timezone,
                data: [user],
                doctors: user.role.name === "doctor" ? 1 : 0,
                patients: user.role.name === "patient" ? 1 : 0
              });
            }
          });

          this.usersbylocation = this.usersbylocation.sort((a, b) => {
            if (a.data.length < b.data.length) return 1;
            else if (a.data.length > b.data.length) return -1;
            else return 0;
          });

          this.userchartOptions.xaxis.categories = this.usersbylocation.map(
            l => {
              return l.zone;
            }
          );
          this.usersByRegion.push({
            name: "doctors",
            data: this.usersbylocation.map(l => {
              return l.doctors;
            })
          });
          this.usersByRegion.push({
            name: "patients",
            data: this.usersbylocation.map(l => {
              return l.patients;
            })
          });
          this.isUserChartDoneLoading = true;
        }
      });
    }
  },
  created() {
    this.$root.currentpage = "dashboard";
    this.$department.getDepartments().then(data => {
      this.departments = [{ id: 0, name: "All" }].concat(data);
    });
  },
  mounted() {
    this.getAppStats();
    this.getUsersStats();
    this.$util.EventBus.$on("GlobalClick", data => {
      console.log("global click", data);
      switch (data) {
        case "stats-users":
          this.page = "users";
          break;
        case "stats-apps":
          this.page = "apps";
          break;
        case "stats-questions":
          this.page = "questions";
          break;
        default:
          this.page = "users";
      }
    });
  },
  data() {
    return {
      value: [0, 2, 5, 9, 5, 10, 3, 5, 1, 10],
      usersStats: [
        { id: 1, name: "Total Users", icon: "user", stat: -1 },
        { id: 2, name: "Online Now", icon: "wifi", stat: -1 },
        { id: 3, name: "Doctors", icon: "user-md", stat: -1 },
        { id: 4, name: "Patients", icon: "user", stat: -1 }
      ],
      appsStats: [
        { id: 1, name: "Total", icon: "calender", stat: -1 },
        { id: 2, name: "Total Video", icon: "video", stat: -1 },
        { id: 3, name: "Total Clinic", icon: "hospital", stat: -1 },
        { id: 4, name: "In Progress", icon: "spinner-alt", stat: -1 }
      ],
      isAppsChartDoneLoading: false,
      appsSeries: [],
      departments: [],
      apps: [],
      department: { id: 0, name: "All" },
      usersbylocation: [],
      usersByRegion: [],
      isUserChartDoneLoading: false,
      page: "users",
      usersShowChart: true,
      appschartOptions: {
        chart: {
          type: "area",
          height: 400,
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false // you can either change hear to disable all grids
        },
        colors: ["#008FFB", "#00E396", "#CED4DC"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "left"
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true
          }
        }
      },
      userchartOptions: {
        chart: {
          type: "bar",
          height: 400,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false // you can either change hear to disable all grids
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            borderRadius: 5
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 0.5,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true
          }
        },

        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        }
      }
    };
  }
};
</script>

<style></style>
